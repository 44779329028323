<template>
  <div class="container">
    <div class="form-group">
      <label>Select Template</label>
      <vue-select
        :options="templates"
        label="name"
        v-model="selected_template"
        placeholder="Select Template"
        required
      ></vue-select>
    </div>
    <div class="form-group">
      <input type="checkbox" v-model="terms" value="1" id="terms" />
      <label for="terms" style="margin-left: 10px;">
        I have read the
        <a href="#" @click.prevent="show_terms=true" style="color:#459246">terms and conditions.</a>
      </label>
    </div>
    <hr />
    <div class="form-group">
      <div
        class="preview"
        id="bid-bond-template"
        style="background: #fff url(/img/sample.png) no-repeat; background-size: contain; background-position-x: center;"
      >
        <div v-html="preview"></div>
      </div>
    </div>
    <hr />
    <div class="form-group" style="text-align: right; padding: 0 20px;">
      <button class="btn btn-secondary" @click.prevent="selectTemplate">Proceed</button>
      <a href class="btn btn-info pull-left" @click.prevent="$emit('back')">Previous</a>
    </div>
    <modal :showModal="show_terms" @close="show_terms=false">
      <template v-slot:header>
        <h5>Terms and Conditions</h5>
      </template>
      <div>
        <terms-and-conditions />
      </div>
      <hr />
      <div class="form-group">
        <a
          href="#"
          class="btn btn-primary"
          @click.prevent="show_terms = false; terms = 1;"
        >Accept Terms and Conditions</a>
        <a href="#" class="btn btn-danger pull-right" @click.prevent="show_terms = false">Cancel</a>
      </div>
    </modal>
  </div>
</template>

<script>
import TermsAndConditions from "@/components/dashboard/Terms.vue";

export default {
  components: {
    TermsAndConditions
  },

  mounted() {
    this.fetch();
  },

  data() {
    return {
      templates: [],
      selected_template: {},
      preview: "",
      terms: 0,
      show_terms: false
    };
  },

  methods: {
    fetch() {
      this.$axios.get("/api/v1/dashboard/bond-templates?type=bidbond").then(response => {
        this.templates = response.data;
        this.selected_template = this.templates[0];
      });
    },

    selectTemplate() {
      if (this.terms == 1) {
        return this.$emit("selected", this.selected_template.id);
      } else {
        window.scrollTo(0, 0);
        this.$alert.error("Please accept the terms and conditions");
      }
    }
  },

  watch: {
    selected_template() {
      this.axios
        .get("/api/v1/dashboard/bond-templates/" + this.selected_template.id)
        .then(response => {
          this.preview = response.data;
        });
    }
  }
};
</script>

<style lang="scss">
.preview {
  background: #fff;
  padding: 20px;
}
</style>