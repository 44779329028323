<template>
  <div>
    <form @submit.prevent="$emit('step2', bid_bond)">
      <div class="form-group">
        <label>BidBond Amount</label>
        <vue-numeric v-model="bid_bond.amount" class="form-control" />
      </div>
      <div class="form-group">
        <label>Tender Period</label>
        <select class="form-control" v-model="bid_bond.period" required="">
          <option value="30">30 Days</option>
          <option value="60">60 Days</option>
          <option value="90">90 Days</option>
          <option value="120">120 Days</option>
          <option value="180">180 Days</option>
          <option value="210">210 Days</option>
          <option value="365">365 Days</option>
        </select>
      </div>
      <div class="form-group">
        <label>Start Date</label>
        <date-picker
          v-model="bid_bond.start_date"
          format="yyyy-MM-dd"
          name="bid_bond_start_date"
          input-class="form-control bg-white"
          :disabledDates="disabledDates"
					placeholder="Start Date"
        />
      </div>
      <div class="form-group">
        <label>End Date</label>
        <input
          type="text"
          class="form-control"
          v-model="end_date"
          readonly=""
					placeholder="End Date"
        />
      </div>
      <div class="form-group">
        <a href="" class="btn btn-info pull-left" @click.prevent="$emit('back')"
          >Previous</a
        >
        <button class="btn btn-secondary pull-right">Next</button>
      </div>
    </form>
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "vuejs-datepicker";

export default {
  components: {
    DatePicker,
  },

  mounted() {
    this.current.amount
      ? (this.bid_bond.amount = this.current.amount)
      : (this.bid_bond.amount = "");
    this.current.period
      ? (this.bid_bond.period = this.current.period)
      : (this.bid_bond.period = "");
    this.current.start_date
      ? (this.bid_bond.start_date = this.current.start_date)
      : (this.bid_bond.start_date = "");
  },

  data() {
    return {
      bid_bond: {
        amount: "",
        period: "",
        start_date: "",
        end_date: "",
      },
      disabledDates: {
        to: new Date(Date.now() - 8640000),
      },
    };
  },

  props: {
    current: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  computed: {
    end_date() {
      if (this.bid_bond.start_date && this.bid_bond.period) {
        return moment(this.bid_bond.start_date)
          .add(parseInt(this.bid_bond.period), "days")
          .format("YYYY-MM-DD");
      }

      return "";
    },
  },

  watch: {
    end_date(newval) {
      this.bid_bond.end_date = newval;
    },

    current(newval) {
      newval.amount
        ? (this.bid_bond.amount = newval.amount)
        : (this.bid_bond.amount = "");
      newval.period
        ? (this.bid_bond.period = newval.period)
        : (this.bid_bond.period = "");
      newval.start_date
        ? (this.bid_bond.start_date = newval.start_date)
        : (this.bid_bond.start_date = "");
    },
  },
};
</script>
