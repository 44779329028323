<template>
  <div>
    <page-title :title="`Generate Bidbond`" />
    <div class="row row-sm">
      <div class="col-md-10">
        <div class="card">
          <div class="card-body">
            <template>
              <template v-if="step == 1">
                <step-one
                  :companies="companies"
                  :banks="banks"
                  :counter_parties="counter_parties"
                  @step1="step1"
                  :current="bid_bond"
                />
              </template>
              <template v-if="step == 2">
                <step-two @step2="step2" @back="step = 1" :current="bid_bond" />
              </template>
              <template v-if="step == 3">
                <template v-if="show_summary">
                  <step-three
                    :bid_bond="bid_bond"
                    :pricing="pricing"
                    @back="step = 2"
                    @step3="step3"
                  />
                </template>
              </template>
              <template v-if="step == 4">
                <select-template :bidbond="bid_bond" @selected="selectTemplate" @back="step = 3" />
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import SelectTemplate from "./components/SelectTemplate.vue";
import StepOne from "./components/StepOne.vue";
import StepTwo from "./components/StepTwo.vue";
import StepThree from "./components/StepThree.vue";

export default {
  components: {
    SelectTemplate,
    StepOne,
    StepTwo,
    StepThree
  },

  mounted() {
    this.fetch();
  },

  data() {
    return {
      bid_bond: {},
      step: 1,
      counter_parties: [],
      error: "",
      pricing: {},
      companies: [],
      banks: [],
      show_summary: false
    };
  },

  methods: {
    fetch() {
      this.$loader.start()
      this.$axios.get("/api/v1/dashboard/bidbonds/create").then(response => {
        this.counter_parties = response.data.counterparties;
        this.companies = response.data.companies;
        this.banks= response.data.banks;
        this.$loader.stop()
      });
    },

    extend(obj, src) {
      for (var key in src) {
        if (src.hasOwnProperty(key)) obj[key] = src[key];
      }

      return obj;
    },

    calculatePricing() {

      let bid_bond = {
        amount: this.bid_bond.amount,
        timeline:this.bid_bond.period,
      };

      this.show_summary = false;
      this.$loader.start()

      this.$axios.post("/api/v1/dashboard/bidbonds/getpricing", bid_bond).then(response => {
        this.pricing = response.data;
        this.show_summary = true;
        this.$loader.stop()
      });
    },

    step1(bid_bond) {
      this.bid_bond = bid_bond;
      this.step = 2;
    },

    step2(bid_bond) {
      
      this.bid_bond = this.extend(this.bid_bond, bid_bond);
      this.calculatePricing();
      this.step = 3;
    },

    step3() {
      this.step = 4;
    },

    selectTemplate(secret) {
      
      this.bid_bond.template = secret;
      this.bid_bond.start_date = this.$moment.moment(this.bid_bond.start_date).format(
        "YYYY-MM-DD"
      );

      this.bid_bond.company_id = this.bid_bond.company.id;
      this.bid_bond.bank_id = this.bid_bond.bank.unique_id;
      this.bid_bond.counterparty_id = this.bid_bond.counter_party.id;
      this.$loader.start()

      this.$axios
        .post("/api/v1/dashboard/bidbonds", this.bid_bond)
        .then(response => {
          this.$loader.stop()
          this.$router.push({
            name: "bidbonds.payment",
            params: { id: response.data.unique_id }
          });
        })
        .catch(error => {
          this.$loader.stop()
          this.$http(error.response);
        });
    }
  }
};
</script>

<style lang="scss">
.bond-content {
  p {
    font-weight: bold;

    span {
      font-weight: normal;
      font-size: 14px;
    }
  }
}
</style>